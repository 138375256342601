import { useGetVideoReports } from '@/api/video/useGetVideoReport';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
const data = {
  datasets: [
    {
      data: [1233, 2500],
      backgroundColor: ['#A693F8', '#a4c3ef'],
      spacing: 2,
      borderColor: 'transparent',
      borderRadius: 5,
    },
  ],
  labels: ['Male', 'Female'],
};
const options: any = {
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        color: 'black',
        boxWidth: 20,
        boxHeight: 20,
        borderRadius: 10,
      },
    },
  },
  cutout: '80%',
};
const DoughnutQuickLearn = () => {
  const { data: videos } = useGetVideoReports('mostViews');
  console.log(videos);

  return (
    <div>
      {/* <div className="h-[265px] w-[265px] rounded-2xl border border-[#217FCE] bg-[#E5EFF9] p-5">
        <Doughnut data={data} options={options} />
      </div> */}
      <div className="my-2 h-full rounded-2xl border border-[#217FCE] bg-[#E5EFF9] p-5">
        <p>most viewed video list</p>
        {videos?.data.map((video, index) => {
          return (
            <Link to={`/videos/detail/${video.id}`} className="text-blue-400" state={video} key={video.id}>
              <p className=" hover:underline">
                {index + 1}. {video.title}
              </p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default DoughnutQuickLearn;
