import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const TagSlice = createSlice({
  name: 'tags',
  initialState: { id: '', name: '' },
  reducers: {
    setTag: (state, action: PayloadAction<{ id: string; name: string }>) => (state = action.payload),
  },
});

export default TagSlice.reducer;
export const { setTag } = TagSlice.actions;
