import { Category } from '@/types/category';

const MainCategoryCard = (props: { tag: Category }) => {
  return (
    <div className=" h-[200px] w-[200px] rounded-md ">
      <img src={props.tag.thumbnailUrl} alt="" className=" h-[160px] w-full p-3" />
      <p className="text-center">{props.tag.name}</p>
    </div>
  );
};

export default MainCategoryCard;
