import { createBrowserRouter, Outlet } from 'react-router-dom';
import MainDashboard from './pages/MainDashboard';
import AppLayout from './layout/AppLayout.tsx';

import SignIn from '@/pages/auth/SignIn.tsx';
import ErrorPage from '@/pages/error';
import Setting from './pages/setting/Setting.tsx';
import Category from './pages/quick-learn/category/index.tsx';
import VideoList from './pages/quick-learn/videos/index.tsx';
import CreateVideo from './pages/quick-learn/videos/CreateVideo.tsx';
import Users from '@/pages/users';
import VideoDetail from './pages/quick-learn/videos/VideoDetail.tsx';
import UserDetail from './pages/users/UserDetail.tsx';
import UpdateVideo from './pages/quick-learn/videos/EditVideo.tsx';
import AddUser from './pages/users/AddUser.tsx';
import UserCountdown from './pages/users/user-countdown.tsx';
import SubCategory from './pages/quick-learn/category/subCategory.tsx';
import Slide from './pages/customization/slide';
import Home from './pages/home/index.tsx';

export const router = createBrowserRouter([
  {
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <MainDashboard />,
        index: true,
      },
      {
        path: 'videos',
        element: <Outlet />,
        children: [
          {
            element: <VideoList />,
            // path: '?page=1&pageLimit=10',
            index: true,
          },
          {
            element: <CreateVideo />,
            path: 'create',
          },
          {
            element: <UpdateVideo />,
            path: 'edit/:id',
          },
          {
            element: <VideoDetail />,
            path: 'detail/:id',
          },
        ],
      },
      {
        path: 'home',
        element: <Outlet />,
        children: [
          { index: true, element: <Home /> },
          {
            element: <Outlet />,
            path: 'category',
            children: [
              {
                element: <Category />,
                index: true,
              },
              {
                path: ':id',
                element: <SubCategory />,
              },
            ],
          },
          {
            path: 'slide',
            element: <Slide />,
          },
        ],
      },
      {
        path: 'setting',
        element: <Setting />,
      },
      {
        path: 'users',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <Users />,
          },
          {
            path: 'detail/:id',
            element: <UserDetail />,
          },
          {
            path: 'add-user',
            element: <AddUser />,
          },
          {
            path: 'user-countdown',
            element: <UserCountdown />,
          },
        ],
      },
    ],
  },
  { path: '*', element: <SignIn /> },
]);
