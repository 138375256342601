import axios from 'axios';

// export const baseURL = import.meta.env.VITE_API_BASE_URL;
export const baseURL = 'https://qlbp.ilbc.edu.mm/api';

const apiClient = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      config.headers['Authorization'] = `Bearer ${access_token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// let isInitialFetch = true;
// apiClient.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     if (error?.response?.status === 401 && isInitialFetch) {
//       const refreshToken = localStorage.getItem('refresh_token');
//       const { data } = await apiClient.post('auth/refresh-token', {
//         refreshToken,
//       });
//       isInitialFetch = false;
//       const newAccessToken = data.access_token;
//       const newRefreshToken = data.refresh_token;
//       localStorage.setItem('access_token', newAccessToken);
//       localStorage.setItem('refresh_token', newRefreshToken);
//     }
//   },
// );

export default apiClient;
