import { useGetAllCategory } from '@/api/category/useGetAllCategory';
import CustomDialog from '@/components/common/CustomDialog';
import { useState } from 'react';
import CreateCategory from './CreateCategory';
import { useDeleteCategory } from '@/api/category/useDeleteCategory';
import { toast } from 'react-toastify';
import UpdateCategory from './EditCategory';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import { setTag } from '@/store/slices/tags.slice';

import TopBarOfTag from '@/components/quick-learn/TopBarOfTag';
import {
  DeleteButton,
  EditButton,
} from '@/components/common/IconButtons/IconButtons';
import useSetUrlQuery from '@/utils/query';
import ConfirmBox from '@/components/common/form/ConfirmBox';
import { useParams, useSearchParams } from 'react-router-dom';
import QuickLoader from '@/components/common/QuickLoader';

const SubCategory = () => {
  const [confirm, setConfirm] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const tag = useAppSelector((state) => state.tagSlice);
  const [searchParams] = useSearchParams();
  const tags = searchParams.get('tag') || '';
  const { id: parentId } = useParams();
  console.log(parentId);
  const { data, isLoading } = useGetAllCategory({ search: tags, parentId });
  const { mutate } = useDeleteCategory();
  const dispatch = useAppDispatch();
  const { deleteQuery } = useSetUrlQuery();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const deleteHandler = (tag: { id: string; name: string }) => {
    dispatch(setTag(tag));
    setConfirm(true);
  };
  const handleConfirm = () => {
    const id = tag.id;
    mutate(id, {
      onError() {
        toast.error('error deleting category');
      },
      onSuccess() {
        toast.success('delete category successfully');
        setConfirm(false);
      },
    });
  };
  const handleEdit = (tag: { id: string; name: string }) => {
    setIsDialogOpen(true);
    setIsEditMode(true);
    dispatch(setTag(tag));
  };
  if (isLoading) {
    return <QuickLoader />;
  }

  return (
    <div className=" m-5 rounded-xl bg-white p-5">
      <ConfirmBox
        description="Are you sure to delete?"
        onCloseHandle={() => {
          deleteQuery({ key: 'deleteId' });
          setConfirm(false);
        }}
        onConfirm={handleConfirm}
        open={confirm}
      />
      <div className="flex items-center justify-between ">
        <h2>Category List</h2>
        <TopBarOfTag
          setIsEditMode={setIsEditMode}
          setIsDialogOpen={setIsDialogOpen}
        />
      </div>

      <div className=" mt-4">
        {data?.data.length === 0 ? (
          <p className="">No sub category found</p>
        ) : (
          <table className="ext-gray-500 table-color w-full rounded-lg text-left text-sm ">
            <thead className=" bg-[#97B3CE] text-xs uppercase">
              <tr>
                <th scope="col" className="px-6 py-3">
                  No.
                </th>
                <th scope="col" className="px-6 py-3">
                  Category Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Thumbnail
                </th>
                <th scope="col" className="px-6 py-3">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.data.map((tag) => {
                return (
                  <tr
                    className="border-t odd:bg-[#F1F3F6] even:bg-[#E5EFF9]"
                    key={tag.id}
                  >
                    <th
                      scope="row"
                      className="whitespace-nowrap px-6 py-2 font-medium "
                    >
                      {tag.id}
                    </th>
                    <th
                      scope="row"
                      className="whitespace-nowrap px-6 py-2 font-medium "
                    >
                      {tag?.name}
                    </th>
                    <th
                      scope="row"
                      className="whitespace-nowrap px-6 py-2 font-medium "
                    >
                      <img
                        src={tag?.thumbnailUrl}
                        alt="sdfasdf"
                        className="h-10 w-10"
                      />
                    </th>

                    <td className="flex gap-4 px-6 py-4">
                      <button onClick={() => deleteHandler(tag)}>
                        <DeleteButton />
                      </button>
                      <button onClick={() => handleEdit(tag)}>
                        <EditButton disabled={false} />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <CustomDialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          deleteQuery({ key: 'editId' });
        }}
      >
        <div className=" w-[350px] ">
          {isEditMode ? (
            <UpdateCategory setIsDialogOpen={setIsDialogOpen} />
          ) : (
            <CreateCategory setIsDialogOpen={setIsDialogOpen} />
          )}
        </div>
      </CustomDialog>
    </div>
  );
};

export default SubCategory;
