import { Link } from 'react-router-dom';
import { useGetAllVideos } from '@/api/video/useGetAllVideos';
import { useDeleteVideo } from '@/api/video/useDeleteVideo';
import { toast } from 'react-toastify';
import PaginateFoot from '@/components/quick-learn/PaginateFoot';
import ConfirmBox from '@/components/common/form/ConfirmBox';
import { useState } from 'react';

import TopBarOfVideo from '@/components/quick-learn/TopBarOfVideo';
import {
  DeleteButton,
  EditButton,
  InfoButton,
} from '@/components/common/IconButtons/IconButtons';
import QuickLoader from '@/components/common/QuickLoader';
import useSetUrlQuery from '@/utils/query';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import { setVideoToDelete } from '@/store/slices/video.slice';
import { MoreHorizontal } from 'lucide-react';
import { truncateText } from '@/utils';

const VideoList = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // data from searchParams
  const { getQuery } = useSetUrlQuery();
  const dispatch = useAppDispatch();
  const pageLimit = getQuery('pageLimit') || '10';
  const page = getQuery('page') || '1';
  const title = getQuery('title') || '';
  const tagName = getQuery('tag');
  // const tagName = getQuery('tag') == 'all' ? '' : getQuery('tag');
  const deleteId = useAppSelector((state) => state.videoSlice.id);

  const { data, isLoading } = useGetAllVideos(page, pageLimit, title, tagName);
  console.log(tagName);

  const pageCount = Math.ceil(data?.pagination.total / Number(pageLimit));
  // delete video hook
  const { mutate, error } = useDeleteVideo();
  const [selectedVideos, setSelectedVideos] = useState([]);

  const deleteHandler = (video: any) => {
    setIsOpen(true);
    dispatch(setVideoToDelete(video.id));
  };

  const handleConfirm = (isConfirm: boolean) => {
    if (!isConfirm) {
      setIsOpen(false);
    }

    if (isConfirm) {
      setIsOpen(false);
      mutate(deleteId, {
        onError() {
          toast.error('error deleting video');
        },
        onSuccess() {
          toast.success('delete video successfully');
        },
      });
    }
  };
  // console.log(selectedVideos);

  const handleSelectChange = (videoId: string) => {
    const isExitInSelect = selectedVideos.find((id) => id === videoId);
    if (!isExitInSelect) {
      //@ts-expect-error
      setSelectedVideos([...selectedVideos, videoId]);
    } else setSelectedVideos(selectedVideos.filter((id) => id !== videoId));
  };
  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      //@ts-expect-error
      setSelectedVideos(data.data?.map((video) => video.id));
    } else setSelectedVideos([]);
  };
  const handleMassDelete = () => {
    return;
    const selectedVideoIds = selectedVideos.join(',');
    console.log(selectedVideoIds);
    if (confirm('are you sure to delete selected all video?')) {
      mutate(selectedVideoIds, {
        onSuccess() {
          toast.success('Delete videos successfully!');
          setSelectedVideos([]);
        },
        onError() {
          console.log(error?.response?.data);
          toast.error(error?.response?.data);
        },
      });
    }
  };
  if (isLoading) {
    return <QuickLoader />;
  }
  return (
    <div className="m-4 mr-5 h-full w-full overflow-hidden overflow-y-auto rounded-tl-md bg-white shadow-md">
      <ConfirmBox
        description="Are you sure to delete"
        open={isOpen}
        onConfirm={(isConfirm) => handleConfirm(isConfirm)}
        onCloseHandle={() => {
          setIsOpen(false);
        }}
      />
      <div className="flex flex-col gap-4  p-8">
        <div className="flex items-center justify-between ">
          <p className=" text-xl font-bold">Video List</p>
          <TopBarOfVideo />
        </div>
        {/* video list table start  */}
        {data?.data?.length === 0 ? (
          <p className=" text-center">No Result</p>
        ) : (
          <div className=" relative h-[420px] overflow-auto rounded-lg ">
            {selectedVideos?.length !== 0 && (
              <div className=" absolute flex w-full justify-between bg-slate-300 px-6 py-2">
                <div className="flex items-center justify-center gap-3">
                  <input
                    type="checkbox"
                    name="userIds"
                    // checked={selectedVideos.length !== 0}
                    onChange={(e) => handleSelectAll(e)}
                  />
                  Select All
                </div>
                <button onClick={handleMassDelete}>
                  <MoreHorizontal />
                </button>
              </div>
            )}
            <table className="w-full  text-left text-sm">
              <thead className="bg-[#97B3CE] text-xs uppercase ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    checked
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Video Title
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Size
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Category
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Author
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {data?.data?.map((video: any) => {
                  return (
                    <tr
                      className="border-t odd:bg-[#F1F3F6] even:bg-[#E5EFF9]"
                      key={video.id}
                    >
                      <td className="px-6 py-4">
                        <input
                          type="checkbox"
                          name="videoId"
                          checked={selectedVideos?.find(
                            (id) => id === video.id,
                          )}
                          value={video.id}
                          onChange={() => handleSelectChange(video.id)}
                        />
                      </td>
                      <td
                        scope="row"
                        className="whitespace-nowrap px-6 py-4 font-medium "
                      >
                        {truncateText(video?.title, 10)}
                      </td>
                      <td>
                        {Math.round(video?.contentLength / (1024 * 1024))} MB
                      </td>
                      <td className="px-6 py-4">{video?.tags}</td>
                      <td className="px-6 py-4">{video?.author?.username}</td>
                      <td className="flex items-center justify-start gap-4 px-6  py-6">
                        <Link
                          to={`/videos/edit/${video.id}`}
                          state={video}
                          className="w-6"
                        >
                          <EditButton disabled={false} />
                        </Link>
                        <button onClick={() => deleteHandler(video)}>
                          <DeleteButton />
                        </button>
                        <Link
                          className="w-6"
                          to={`/videos/detail/${video.id}`}
                          state={video}
                        >
                          <InfoButton />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {/* video list table end  */}
        <PaginateFoot total={data?.pagination?.total} pageCount={pageCount} />
      </div>
    </div>
  );
};
export default VideoList;
