import { Button, buttonVariants } from '@/components/common/Button';
import InputBox from '@/components/common/form/InputBox';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { cn } from '@/utils';
import { toast } from 'react-toastify';
import { useAppSelector } from '@/store/hook';
import { useState } from 'react';
import { CreateSlideSchema } from '@/validators/slide.d';
import { useUpdateSlide } from '@/api/banners/useUpdateSlide';
import { usePublicSlide } from '@/api/banners/usePublishSlide';

type CreateCategoryProps = {
  setIsDialogOpen: (isOpen: boolean) => void;
};

const EditSlide = ({ setIsDialogOpen }: CreateCategoryProps) => {
  const banner = useAppSelector((state) => state.BannerSlice);
  const [isPublic, setIsPublic] = useState(banner.isPublished);
  const { mutate, isLoading, error } = useUpdateSlide();
  const [thumbnail, setThumbnail] = useState<Blob>();
  const [imageUrl, setImageUrl] = useState('');
  const publicSlide = usePublicSlide();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: banner.name,
      link: banner.link,
      content: banner.content,
    },
    resolver: yupResolver(CreateSlideSchema),
  });

  const EditSlide = async (data: any) => {
    let formData = new FormData();
    formData.append('name', data.name);
    formData.append('link', data.link);
    formData.append('content', data.content);
    //@ts-expect-error
    formData.append('thumbnail', thumbnail);
    mutate(
      { id: banner.id, slide: formData },
      {
        onSuccess() {
          setIsDialogOpen(false);
          toast.success('Category updated successfully ');
        },
        onError() {
          //@ts-expect-error
          toast.error(error?.response?.data?.message);
        },
      },
    );
  };

  return (
    <div className=" m-5  w-full rounded-xl bg-white p-5">
      <h3 className=" mb-4 text-lg font-bold">Edit Category Name</h3>
      <form onSubmit={handleSubmit(EditSlide)} className="flex flex-col items-center justify-center">
        <div className="w-full">
          <InputBox
            placeholder="Enter new category"
            type="text"
            id="tag"
            name="name"
            register={register}
            defaultValue={banner?.name}
          />

          <p className="mt-2 text-red-700">{errors.name?.message}</p>
        </div>
        <div className="w-full">
          <InputBox placeholder="Enter content" type="text" id="content" name="content" register={register} />
          <p className="mt-2 text-red-700">{errors.content?.message}</p>
        </div>
        <div className="w-full">
          <InputBox placeholder="Link" type="text" name="link" id="link" register={register} />
          <p className="mt-2 text-red-700">{errors.link?.message}</p>
        </div>
        <div className="w-full ">
          <label
            htmlFor="cat_image"
            className={cn(
              'block h-[200px] w-full overflow-hidden rounded-2xl border-2 border-primary text-center',
              !imageUrl && 'py-20',
            )}
          >
            {imageUrl ? <img src={imageUrl} alt="" className=" h-full w-full object-cover" /> : '+'}
          </label>
          <input
            type="file"
            className="hidden"
            id="cat_image"
            onChange={(e) => {
              if (e.target.files) {
                const file = e.target.files[0];
                const url = URL.createObjectURL(file);
                setImageUrl(url);
                setThumbnail(file);
              }
            }}
          />
          <div className="mt-3 flex w-full items-center justify-start gap-3">
            <button
              type="button"
              onClick={() => {
                setIsPublic(false);
                publicSlide.mutate({ id: banner.id });
              }}
            >
              <input type="radio" name="isPublic" checked={!isPublic} onChange={() => {}} /> Private
            </button>
            <button
              type="button"
              onClick={() => {
                setIsPublic(true);
                publicSlide.mutate({ id: banner.id });
              }}
            >
              <input type="radio" name="isPublic" checked={isPublic} onChange={() => {}} />
              Public
            </button>
          </div>
        </div>
        <Button
          isLoading={isLoading}
          className={cn(buttonVariants({ variant: 'default', size: 'sm' }), 'mt-4 px-6 text-sm')}
        >
          Update
        </Button>
      </form>
    </div>
  );
};

export default EditSlide;
