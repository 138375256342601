import { useForm } from 'react-hook-form';
import InputBox from './common/form/InputBox';
import { Button } from './common/Button';
import { X } from 'lucide-react';
import { useRef, useState } from 'react';
import { cn } from '@/utils';
import useOuterClick from '@/utils/useOuterClick';
import { yupResolver } from '@hookform/resolvers/yup';
import { mixed, object, string } from 'yup';
import { useCreateNotification } from '@/api/notifications/useCreateNotification';
import { toast } from 'react-toastify';
import ConfirmBox from './common/form/ConfirmBox';
const NotiValidation = object({
  title: string().required(),
  body: string().required(),
  image: mixed().optional(),
});
const CreateNotification = () => {
  const [notificationForm, setNotificationForm] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    watch,
  } = useForm({ resolver: yupResolver(NotiValidation) });
  const title = watch('title');
  const body = watch('body');
  const image = watch('image');

  const notiRef = useRef(null);
  const { mutate, error } = useCreateNotification();
  useOuterClick(notiRef, () => setNotificationForm(false));

  const run = () => {
    setNotificationForm(false);
    setIsOpenConfirm(true);
  };

  function confirm() {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('body', body);
    if (image) {
      formData.append('image', (image as any)[0]);
    }
    mutate(formData, {
      onError() {
        //@ts-expect-error
        toast.error(error?.response?.data?.message);
      },
      onSuccess() {
        toast.success('Sent notifications to all devices successfully');
        reset();
        setNotificationForm(false);
      },
    });
  }
  return (
    <div className="">
      <Button
        variant="transparent"
        size="lg"
        className=" rounded-xl border-2"
        onClick={() => setNotificationForm(true)}
      >
        Send Push Notification
      </Button>
      <div
        className={cn(
          'fixed left-0 top-0 z-10 flex h-screen w-full items-center justify-center transition-colors duration-500',
          notificationForm ? ' mt-0 bg-black/40' : '-mt-[800px] bg-transparent',
        )}
      >
        <div
          ref={notiRef}
          className={cn(
            ' relative w-[500px] space-y-4 rounded-xl border  border-gray-300 bg-transparent bg-white  p-3 px-14 py-8 transition-all duration-500',
            notificationForm ? 'mt-0' : '-mt-[800px]',
          )}
        >
          <h2 className=" text-center text-xl">Create Push Notification</h2>
          <div className="w-full">
            <input type="file" {...register('image')} id="noti-image" className=" hidden" />
            <label htmlFor="noti-image" className=" block cursor-pointer rounded-xl bg-blue-200">
              <div className="flex h-20 w-full gap-3 p-3 text-sm">
                <div className="">image</div>
                <div className="">
                  <p>Photo</p>
                  <p>PNG or JPG no bigger than 1MB.</p>
                </div>
              </div>
            </label>
          </div>
          <form onSubmit={handleSubmit(run)} className="flex flex-col items-center justify-center gap-3">
            <InputBox
              id="title"
              name="title"
              register={register}
              type="text"
              placeholder="Message title"
              error={errors.title?.message}
            />
            <textarea
              className=" w-full rounded-xl border-2 border-primary p-2 text-sm"
              id="body"
              {...register('body')}
              placeholder="Enter Message"
            />
            <p className=" text-red-500">{errors.body?.message}</p>
            <Button className=" mx-auto w-[150px] rounded-xl bg-primary">Send</Button>
          </form>
          <ConfirmBox
            description="Are you sure to send notification?"
            onCloseHandle={() => setIsOpenConfirm(false)}
            onConfirm={confirm}
            open={isOpenConfirm}
          />
          <button
            className="absolute right-3 top-0 rounded-full border border-slate-500"
            onClick={() => setNotificationForm(false)}
          >
            <X />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateNotification;
