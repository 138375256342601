import { useDeleteSlide } from '@/api/banners/useDeleteSlide';
import { useGetSlides } from '@/api/banners/useGetSlide';
import { Button, buttonVariants } from '@/components/common/Button';
import CustomDialog from '@/components/common/CustomDialog';
import { DeleteButton, EditButton } from '@/components/common/IconButtons/IconButtons';
import CreateSlide from '@/components/slides/CreateSlide';
import EditSlide from '@/components/slides/EditSlide';
import { useAppDispatch } from '@/store/hook';
import { setBanner } from '@/store/slices/slide.slice';
import { cn } from '@/utils';
import useSetUrlQuery from '@/utils/query';
import { useState } from 'react';
import { toast } from 'react-toastify';

const Slide = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatcher = useAppDispatch();
  const { data } = useGetSlides();
  const { mutate, error } = useDeleteSlide();
  const { setQuery, getQuery } = useSetUrlQuery();

  const handleDelete = (id: string) => {
    mutate(
      { id },
      {
        onSuccess() {
          toast.success('Delete Slide successfully.');
        },
        onError() {
          //@ts-expect-error
          toast.error(error.response.data.message);
        },
      },
    );
  };

  return (
    <div className=" rounded-md p-3">
      <Button
        onClick={() => {
          setIsDialogOpen(true);
          setIsEditMode(false);
        }}
        size="lg"
        className={cn(
          buttonVariants({ variant: 'transparent' }),
          'my-3 rounded-xl border-2 border-blue-500 hover:bg-primary/20',
        )}
      >
        Create Slide
      </Button>
      <select
        className="float-right ml-7 rounded-xl border-2 border-blue-500 p-2"
        value={getQuery('published')}
        onChange={(e) => setQuery({ key: 'published', value: e.target.value, backToFirstPage: false })}
      >
        <option value="true">Public</option>
        <option value="false">Private</option>
      </select>
      <table className="w-full  text-left text-sm">
        <thead className="bg-[#97B3CE] text-xs uppercase ">
          <tr>
            <th scope="col" className="px-6 py-3">
              Name
            </th>
            <th scope="col" className="px-6 py-3">
              Author
            </th>
            <th scope="col" className="px-6 py-3">
              Thumbnail
            </th>

            <th scope="col" className="px-6 py-3">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="">
          {data?.data.map((slide) => {
            return (
              <tr key={slide.id}>
                <td className="px-6 py-3">{slide.name}</td>
                <td className="px-6 py-3">{slide.author.name}</td>
                <td className="px-6 py-3">
                  <img src={slide.imageUrl} alt="slide iamge" className=" h-10 w-10" />
                </td>
                {/* <td className="px-6 py-3">{truncateText(slide.content, 10)}</td> */}
                {/* <td className="px-6 py-3">{slide.isPublished.toString()}</td> */}
                <td className="flex items-center justify-start gap-4 px-6  py-6">
                  <button
                    className="w-6"
                    onClick={() => {
                      setIsEditMode(true);
                      setIsDialogOpen(true);
                      dispatcher(setBanner(slide));
                    }}
                  >
                    <EditButton />
                  </button>
                  <button onClick={() => handleDelete(slide.id)}>
                    <DeleteButton />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <p className=" mt-5 text-center text-xl font-semibold">{data?.data.length === 0 && 'No slide to show'}</p>

      <CustomDialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <div className=" w-[380px] ">
          {isEditMode ? (
            <EditSlide setIsDialogOpen={setIsDialogOpen} />
          ) : (
            <CreateSlide setIsDialogOpen={setIsDialogOpen} />
          )}
        </div>
      </CustomDialog>
    </div>
  );
};

export default Slide;
