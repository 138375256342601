import { Button, buttonVariants } from '@/components/common/Button';
import InputBox from '@/components/common/form/InputBox';
import { useForm } from 'react-hook-form';
import { cn } from '@/utils';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useCreateSlide } from '@/api/banners/useCreateSlide';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateSlideSchema } from '@/validators/slide.d';

type CreateSlideProps = {
  setIsDialogOpen: (isOpen: boolean) => void;
};
const CreateSlide = ({ setIsDialogOpen }: CreateSlideProps) => {
  const { mutate, isLoading, error } = useCreateSlide();
  const [thumbnail, setThumbnail] = useState<Blob | null>(null);
  const [imageUrl, setImageUrl] = useState('');
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      link: '',
      content: '',
    },
    resolver: yupResolver(CreateSlideSchema),
  });
  const createSlide = (data: any) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('link', data.link);
    formData.append('content', data.content);
    //@ts-expect-error
    formData.append('image', thumbnail);
    mutate(formData, {
      onSuccess() {
        setIsDialogOpen(false);
        toast.success('Slide is successfully created');
      },
      onError() {
        //@ts-expect-error
        toast.error(JSON.stringify(error.response.data.message));
      },
    });
  };

  return (
    <div className=" m-5  rounded-xl bg-white p-5">
      <h3 className=" mb-4 text-lg font-bold">Create New Slide</h3>
      <form onSubmit={handleSubmit(createSlide)} className="flex flex-col items-center justify-center">
        <div className="w-full">
          <InputBox placeholder="Enter slide name" type="text" id="name" name="name" register={register} />
          <p className="mt-2 text-red-700">{errors.name?.message}</p>
        </div>
        <div className="w-full">
          <InputBox placeholder="Enter content" type="text" id="content" name="content" register={register} />
          <p className="mt-2 text-red-700">{errors.content?.message}</p>
        </div>
        <div className="w-full">
          <InputBox placeholder="Link" type="text" name="link" id="link" register={register} />
          <p className="mt-2 text-red-700">{errors.link?.message}</p>
        </div>
        <div className="w-full ">
          <label
            htmlFor="cat_image"
            className={cn(
              'block h-[200px] w-full overflow-hidden rounded-2xl border-2 border-primary text-center',
              !imageUrl && 'py-20',
            )}
          >
            {imageUrl ? <img src={imageUrl} alt="" className=" h-full w-full" /> : '+'}
          </label>
          <input
            type="file"
            className="hidden"
            id="cat_image"
            onChange={(e) => {
              if (e.target.files) {
                const file = e.target.files[0];
                const url = URL.createObjectURL(file);
                setImageUrl(url);
                setThumbnail(file);
              }
            }}
          />
          <p className="mt-2 text-sm text-red-700">Slider image must be 1080x330px</p>
        </div>
        <Button
          isLoading={isLoading}
          className={cn(buttonVariants({ variant: 'default', size: 'sm' }), 'mt-4 px-6 text-sm')}
        >
          Create
        </Button>
      </form>
    </div>
  );
};

export default CreateSlide;
